<template>
  <div>
    <div class="input">
      <div class="input_info">
        <p class="input_text">海鼎ID：</p>
        <el-input
          v-model="requestParams.hdId"
          placeholder="请输入海鼎ID"
          size="small"
          clearable
        />
      </div>
    </div>

    <div class="table_style">
      <el-table
        ref="multipleTable"
        v-loading="isLoading.table"
        header-align="center"
        border
        class="main-table"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="id" label="ID" align="center" />
        <el-table-column prop="order_sn" label="订单号码" align="center" />
        <el-table-column prop="user_id" label="用户ID" align="center" />
        <el-table-column prop="hd_id" label="海鼎ID" align="center" />
        <el-table-column prop="cs_id" label="客服ID" align="center" />
        <el-table-column prop="remark" label="备注" align="center" />
        <el-table-column prop="order_status" label="订单状态" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.order_status === 0">未传输</span>
            <span v-if="row.order_status === 1">已传输</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="order_amount"
          label="订单总金额"
          align="center"
        />
        <el-table-column
          prop="order_pack_type"
          label="订单打包类型"
          align="center"
        />
        <el-table-column prop="dis_oder" label="折扣订单" align="center" />
        <el-table-column prop="is_new" label="是否为新品" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.is_new === 0">不是</span>
            <span v-if="row.is_new === 1">是</span>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="下单时间" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.created_at
                ? new Date(row.created_at * 1000).toLocaleString("zh-Hans-CN")
                : "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="updated_at" label="更新时间" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.updated_at
                ? new Date(row.updated_at * 1000).toLocaleString("zh-Hans-CN")
                : "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="operation"
          label="操作"
          align="center"
          width="300"
        >
          <template slot-scope="{ row, $index }">
            <div>
              <el-popover trigger="click" placement="top">
                <p>
                  您确定要恢复ID为<strong>{{ row.id }}</strong
                  >的订单吗?
                </p>
                <div style="text-align: center; margin: 0">
                  <el-button size="mini" type="text" @click="hideOutPopover()"
                    >取消</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    @click.stop="handleRecoverOrder(row, $index)"
                  >
                    确认</el-button
                  >
                </div>
                <el-button
                  slot="reference"
                  type="primary"
                  size="small"
                  plain
                  icon="el-icon-circle-check"
                  style="margin-right: 10px"
                  @click.stop
                  >恢复订单</el-button
                >
              </el-popover>

              <el-button @click="showInfo(row.id)">查看详情</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-drawer
      title="商品列表"
      :visible.sync="showOrderGoods.show"
      :direction="showOrderGoods.direction"
      :before-close="handleClose"
      size="70%"
    >
      <div style="border: 1px solid #218da0; margin: 15px">
        <el-table :data="showOrderGoods.tableData" border style="width: 100%">
          <el-table-column
            prop="goods_item"
            label="商品编号"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="item_name"
            label="商品名称"
            width="380"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="goods_price" label="商品价格" align="center">
          </el-table-column>
          <el-table-column prop="goods_total" label="商品总价" align="center">
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>

    <div ref="footer" class="footer">
      <el-pagination
        :key="reloadPaginate"
        :current-page="pagination.page"
        :page-size="pagination.pageSize"
        :page-sizes="[10, 20, 50, 100, 200]"
        layout="total,sizes, prev, pager, next, jumper"
        :total="pagination.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      requestParams: {
        uid: "",
        hdId: "",
      },
      tableData: [],
      isLoading: {
        table: false,
      },
      reloadPaginate: 0,
      pagination: {
        total: 0,
        page: 1,
        pageSize: 10,
      },
      dialogVisible: false,
      showOrderGoods: {
        show: false,
        //id: 0,
        tableData: [],
        direction: "rtl",
      },
    };
  },
  watch: {
    "requestParams.uid"() {
      this.getTableData();
    },
    "requestParams.hdId"() {
      this.getTableData();
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    handleClose(done) {
      done();
    },
    //显示订单详情
    showInfo(orderId) {
      this.showOrderGoods.show = true;
      this.$axios.get(`/a1/recycle/delOrderGoods/${orderId}`).then((res) => {
        this.showOrderGoods.tableData = res.data.data;
      });
    },
    // 分页跳转
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.getTableData();
    },
    //获取到管理员列表
    getTableData() {
      this.tableData = [];
      this.pagination.total = 0;
      this.$axios
        .get("/a1/recycleList", {
          params: {
            pageSize: this.pagination.pageSize,
            page: this.pagination.page,
            uid: this.requestParams.uid,
            hdId: this.requestParams.hdId,
          },
        })
        .then((res) => {
          const { data } = res.data;
          this.pagination.total = data.total;
          this.tableData = data.data;
          this.reloadPaginate++;
        });
    },
    //恢复订单
    handleRecoverOrder(row, index) {
      this.$axios
        .put(`/a1/recycle/recoverOrder/${row.id}`, {})
        .then(() => {
          this.tableData.splice(index, 1);
          this.$notify({
            title: "成功",
            message: "恢复订单",
            type: "success",
          });
          this.getTableData();
        })
        .catch(() => {
          this.$notify.error({
            title: "失败",
            message: "恢复订单",
          });
        });
    },
    hideOutPopover() {
      this.$refs.footer.click();
    },
  },
};
</script>
<style lang="scss" scoped>
.input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.input_info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 280px;
  margin: 10px 0 10px 10px;
}

.input_text {
  width: 100px;
  color: #889aa4;
}

.footer {
  margin: 100px;
  text-align: center;
}

.table_style {
  margin-left: 10px;
}
</style>
